.container {
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: white;
}
.contentDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 76px;
  height: fit-content;
}
.leftDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.logo {
  width: 5.8rem;
  height: 2.2rem;
  cursor: pointer;
  transition: transform 0.3s;
}

.logo:hover {
  transform: scale(1.1);
}

.contactInfo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
}

.contactInfo:hover .icon {
  transform: scale(1.25);
}
.icon {
  /* background-color: red; */
  transition: transform 0.3s;
}
.contactText {
  text-decoration: underline;
  font-family: "Gothic A1", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #061237;
  line-height: normal;
  transition: transform 0.3s;
}

.contactText:hover {
  transform: scale(1.05);
}

.centerDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: fit-content;
}
.socialTitle {
  font-family: "Gothic A1", sans-serif;
  font-size: 14px;
  font-weight: 800;
  color: #061237;
  text-transform: capitalize;
  white-space: nowrap;
  line-height: normal;
}

.socialIcons {
  background-color: white;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.socialIcons .icon {
  cursor: pointer;
  transition: transform 0.3s;
}

.socialIcons .icon:hover {
  transform: scale(1.05);
}

.rightDiv {
  /* max-width: 382px; */
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.disclaimerText {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #061237;

  text-align: left;
  font-weight: 400;
}
.footerText {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
  color: #061237;
  text-transform: capitalize;
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 16px;
  }
  .contentDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    height: fit-content;
  }
  .leftDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }
}
