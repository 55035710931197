.right img {
	width: 450px;
	height: 450px;
}

.outer {
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.body {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.container {
	width: 912px;
	height: auto;
	padding: 40px;
	position: relative;
	border-radius: 24px;
	border: 1px;
	gap: 24px;
	border: 1px solid #2cccd3;
	color: #2cccd3;
	background-color: white;
}

@media screen and (max-width: 700px) {
	.container {
		padding-bottom: 20px !important;
	}
}

.closeButton {
	width: 48px;
	height: 48px;
	padding: 12px;
	border-radius: 50px;
	border: 1px solid #2cccd3;
	position: absolute;
	top: -10px;
	text-align: center;
	color: #2cccd3;
	right: -10px;
	background: white;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.left {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
}

.left .title {
	font-family: Merriweather Sans;
	font-size: 20px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: left;
	color: #2c3030;
}

.joinButton {
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #2c3030;
	width: 350px;
	padding: 16px;
	border-radius: 25px;
	border: 1px solid #2cccd3;
	gap: 10px;
	background: #2cccd3;
	text-align: center;
	transition: 0.2s all ease-in-out;
}

.joinButton:hover {
	background: transparent;
	border: 1px solid #2cccd3;
}

@media screen and (max-width: 700px) {
	.outer {
		padding-bottom: 10px;
	}
	.body {
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		align-items: center;
		padding-left: 10px;
	}
	.body .title {
		font-size: 16px;
	}
	.container {
		padding: 30px 10px 0px 10px;
		width: 90%;
		height: 90%;
	}

	.body .right {
		display: none;
	}

	.body input {
		width: 300px !important;
	}
	.body .joinButton {
		width: 300px !important;
	}
}

.success_body {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 912px;
	gap: 54px;
}

.success_body .success_header {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
}

.success_body .success_header .title {
	color: #2c3030;
	font-family: Merriweather Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 158.5%;
}

.success_body .success_header .des {
	color: #2c3030;
	width: 603px;
	text-align: center;
	font-family: Merriweather Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 158.5%; /* 25.36px */
}

.success_image img {
	width: 222.997px;
	height: 212.911px;
	flex-shrink: 0;
}

@media screen and (max-width: 700px) {
	.success_body {
		width: auto;
		padding-bottom: 0px;
	}
	.success_body .success_header .des {
		width:100%;
		font-size:14px !important;
	}
	.success_body .title {
		text-align:center;
		font-size: 16px !important;
	}
	.success_body .joinButton {
		width: 270px
	}
}
