@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
body {
  margin: 0;
  /* font-family: "Poppins", sans-serif !important; */
  font-family: "Gothic A1", sans-serif;
}
body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #0a8891;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #076c73;
}

.underline {
  text-decoration-line: underline;
}
