.download_btn_container {
  display: flex;
  padding: 0.875rem 1.25781rem 1.125rem 1.30469rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.875rem;
  background: var(--Pure, #2cccd3);
  transition: .2s all ease-in-out;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.download_btn_container:hover {
  border-radius: 1.875rem;
  border: 1px solid var(--Pure, #2cccd3);
  background: var(--white-100, #fff);
}
.download_btn_content {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
}
.download_btn_text {
  color: #252525;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.content_width {
  width: 16.25rem;
  height: 3.25rem;
  display: inline-block;
}
@media screen and (max-width: 500px) {
}
