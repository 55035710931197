.container {
  padding: 50px 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #2cccd3;
}

.container .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.container .top .title {
  font-family: "Natom Pro", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  color: #061237;
}

.container .top .des {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #061237;
}

.container .body {
  display: grid;
  /* width: 900px; */
  /* justify-content: space-between; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* align-items: flex-start; */
  gap: 32px;
  /* background-color: red; */
}

.body .general {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: flex-start;
}

.body .general .title {
  font-family: "Natom Pro", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  color: #2c3030;
  white-space: nowrap;
}

.body .linkButton {
  /* max-width: 280px; */
  width: 100%;
  padding: 10px 12px;
  border-radius: 25px;
  border: 1px solid #d2d2d2;
  gap: 8px;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #061237;
}

.linkButton:hover {
  border: 1px solid #2cccd3;
  color: #061237;
  background-color: #b2fcff;
}

@media screen and (max-width: 700px) {
  .container .top .title {
    font-family: "Gothic A1", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }
  .container .top .des {
    font-family: "Gothic A1", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .container {
    padding: 24px 18px;
    align-items: flex-start;
  }
  .container .body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .container .body button {
    width: 100% !important;
    max-width: 600px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  .container .general {
    width: 100% !important;
  }
}
