@font-face {
  font-family: "Natom Pro";
  src: url("./fonts/fonnts.com-Natom_Pro_Title_Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url("./fonts/fonnts.com-Natom_Pro_Title_Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url("./fonts/fonnts.com-Natom_Pro_Title_Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url("./fonts/fonnts.com-Natom_Pro_Title_Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url("./fonts/fonnts.com-Natom_Pro_Title_Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url("./fonts/fonnts.com-Natom_Pro_Title_Extra_Light.otf")
    format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
