@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css);
@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);
@font-face {
  font-family: "Natom Pro";
  src: url(/static/media/fonnts.com-Natom_Pro_Title_Black.6c801909.otf) format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url(/static/media/fonnts.com-Natom_Pro_Title_Bold.2b3172f4.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url(/static/media/fonnts.com-Natom_Pro_Title_Medium.65d812c3.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url(/static/media/fonnts.com-Natom_Pro_Title_Regular.e9462498.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url(/static/media/fonnts.com-Natom_Pro_Title_Light.73732c38.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Natom Pro";
  src: url(/static/media/fonnts.com-Natom_Pro_Title_Extra_Light.51ba5961.otf)
    format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: "Poppins", sans-serif !important; */
  font-family: "Gothic A1", sans-serif;
}
body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #0a8891;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #076c73;
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.gb-bg-dark-bluegreen {
	background-color: #2cccd3 !important;
}
.gb-bg-light-bluegreen {
	background-color: rgb(44 204 211 / 60%) !important;
}
.gb-textdark--bluegreen {
	color: #2cccd3 !important;
}
.my-6 {
	margin-top: 5rem !important;
	margin-bottom: 5rem !important;
}

.main-nav-bar-wrapper {
	padding: 36px 0;
	transition: 0.45s;
}
.main-nav-bar-wrapper.gb-sticky-header {
	background: rgb(0 0 0 / 80%);
	padding: 20px 0;
}
.main-nav-bar-wrapper .header-geting-start-btn:hover {
	background: #2cccd3;
	color: #000;
}
.main-nav-bar-wrapper .header-geting-start-btn {
	background: #000000;
	border: 1px solid #2cccd3;
	border-radius: 30px;
	color: #fff;
	padding: 11px 17px;
	color: #2cccd3;
	font-weight: 400;
	font-size: 15px;
	position: absolute;
}
.main-nav-bar-wrapper .cm-menu-links a {
	color: #fff;
}

@media screen and (min-width: 999px) {
	.cm-menu-links {
		display: flex;
		flex-direction: row;
		grid-gap: 4rem;
		gap: 4rem;
	}
	.navbar-expand-lg .navbar-nav {
		padding-left: 10%;
	}
}
.gb-main-mobile-menu {
	font-size: 30px;
}

#main-footer .ftr-power-by-text {
	text-align: left !important;
	color: #929292;
	font-size: 14px;
}

#main-footer .ftr-copy-text {
	text-align: right !important;
	font-size: 14px;
}

/* Footer CSS Start */

@media (max-width: 767.98px) {
	/* #main-footer ul.ftr-list-links{
    margin-top: 30px;
  } */
}
/* Footer CSS End */

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
}

/* Responsive Start */
@media (max-width: 991.98px) {
	.main-nav-bar-wrapper .header-geting-start-btn {
		margin-top: 30px;
		background: #fff;
		color: #000;
		border-color: #000;
	}
	.main-nav-bar-wrapper .main-mobile-drawer {
		background: #2cccd3;
		background: radial-gradient(
			circle,
			rgba(44, 204, 211, 0.8) 0%,
			rgba(0, 0, 0, 1) 90%
		);
	}
}
@media (max-width: 768px) {
	.main-nav-bar-wrapper {
		padding: 25px 0;
		background: #000000;
	}
	.main-nav-bar-wrapper .container {
		padding-left: 20px;
		padding-right: 20px;
	}
	#main-footer {
		padding-left: 20px;
		padding-right: 20px;
		border: none;
	}
	#main-footer .cm-ftr-visa-card {
		max-width: 150px;
		margin-top: 0 !important;
	}
	#main-footer .ftr-copy-text {
		text-align: right !important;
		font-size: 10px;
	}
	#main-footer .ftr-power-by-text {
		text-align: left !important;
		color: #929292;
		font-size: 10px;
	}
	#main-footer ul.ftr-list-links,
	#main-footer .ftr-site-desc {
		margin-top: 60px !important;
	}
	#main-footer ul.ftr-list-links {
		margin-bottom: 30px;
	}
}

/* Responsive End */

.estu-font-refer {
	color: white;
}
.form-control:focus {
	color: white;
	background-color: #fff;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.estu-font-refer-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.form-check-label {
	font-size: 14px;
}

.cursor-pointer:hover {
	cursor: pointer;
}

p {
	margin-top: none;
	margin-bottom: none;
}

.App {
	overflow: hidden;
}

.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
	background-color: #2cccd3;
	color: black;
}

.sub_ctr2__box_label_popup {
	color: black;
}

.validationText {
	font-size: 11px;
	margin-top: 2px;
	margin-left: 2px;
}

.validationTexterror {
	font-size: 15px;
	margin-left: 2px;
}

.nav_container__2kJ9b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 100px;
  width: 100%;
  /* border-bottom: 1px solid #bdbdbd; */
  background: #fff;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04); */
  box-shadow: rgba(0, 0, 0, 0.18) 0px 0.602187px 0.602187px -1.25px,
    rgba(0, 0, 0, 0.16) 0px 2.28853px 2.28853px -2.5px,
    rgba(0, 0, 0, 0.063) 0px 10px 10px -3.75px;
}
.nav_container_contact__TYNEX {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 100px;
  width: 100%;
}

.nav_menus__2-NK8 .nav_mobileNav__1Cs96 {
  display: none;
}
.nav_menus__2-NK8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px; /*TODO: may remove in future, added because tester wants estudeals and leag navbar height matching*/
}

.nav_menus__2-NK8 .nav_container_contact__TYNEX {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.nav_menus__2-NK8 .nav_container_contact__TYNEX .nav_link__17TfW {
  display: flex;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  margin-left: -48px;
  text-align: left;
  color: #252525;
}

.nav_promoteButton__2ryPj {
  top: 0px;
  border-radius: 30px;
  background-color: #2cccd3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 18px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #252525;
}

.nav_logo__3K-on:hover {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .nav_menus__2-NK8 .nav_container_contact__TYNEX {
    display: none;
  }
  .nav_menus__2-NK8 {
    justify-content: space-between;
    width: 100%;
  }
  .nav_promoteButton__2ryPj {
    display: none;
  }
  .nav_container__2kJ9b {
    padding: 20px;
  }
  .nav_menus__2-NK8 .nav_logo__3K-on svg {
    width: 74px;
    height: 25px;
  }
}

@media screen and (min-width: 500px) {
  .nav_menus__2-NK8 .nav_mobileNav__1Cs96 {
    display: none;
  }
  .nav_logo__3K-on {
    padding-left: 0px;
  }
  .nav_menus__2-NK8 {
    padding: 0 0 0 0;
  }
}

.nav_promoteButton__2ryPj {
  position: relative;
  overflow: hidden;
  top: 0px;
  border-radius: 30px;
  background-color: #2cccd3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 18px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #252525;
}

.nav_promoteButton__2ryPj::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  background-color: #46dce3;
  border-radius: 50%;
  opacity: 1;
  transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.3s ease-out;
}

.nav_promoteButton__2ryPj:hover::before {
  width: 500px;
  height: 600px;
  opacity: 0;
}

.nav_mobileNavbar__3MqnS {
  display: none;
}

@media screen and (max-width: 500px) {
  .nav_logo__3K-on img {
    width: 139.67px;
    height: 25px;
  }
  .nav_navContainer__z7UQw {
    display: none;
  }
  .nav_button__1g08j {
    display: none;
  }
  .nav_container__2kJ9b {
    padding: 20px 20px;
  }
  .nav_p1__2qYEw {
    width: 100%;
    grid-gap: 0;
    gap: 0;
    justify-content: space-between;
  }
  .nav_menuIcons__jSAdR {
    display: block;
  }
  .nav_mobileNavbar__3MqnS .nav_navContainer1__3_j_x {
    display: flex;
    flex-direction: column;
  }
  .nav_navContainer1__3_j_x {
    grid-gap: 50px;
    gap: 50px;
    margin-top: 10px;
  }

  .nav_mobileNavbar__3MqnS .nav_close__3EMVj {
    width: 100%;
    display: flex;
    align-items: flex-end;
    text-align: right;
    flex-direction: column;
  }

  .nav_navContainer1__3_j_x .nav_links__khySp {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #252525;
  }
  .nav_mobileNavbar__3MqnS {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    background-color: #ffffff;
    z-index: 1000000;
    padding: 50px 40px;
    justify-content: space-between;
    width: 90vw;
    box-shadow: -3px -9px 20px #80808045;
  }
  .nav_button1__1jDwX {
    border-radius: 100px;
    padding: 16px 44px;
    background: #665ee0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    transition: 0.3s all ease-in-out;
    border: 1px solid #665ee0;
  }

  .nav_button1__1jDwX:hover {
    cursor: pointer;
    color: #665ee0;
    background: transparent;
  }
  .nav_show_btn__3hb0p {
    display: none;
  }
}
.nav_promoteButton1__33fOr {
  position: relative;
  overflow: hidden;
  top: 0px;
  border-radius: 30px;
  background-color: #2cccd3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 10px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  color: #252525;
}

.nav_promoteButton1__33fOr::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  background-color: #46dce3;
  border-radius: 50%;
  opacity: 1;
  transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.3s ease-out;
}

.nav_promoteButton1__33fOr:hover::before {
  width: 500px;
  height: 600px;
  opacity: 0;
}

.downloadBtn_download_btn_container__Dl6Hz {
  display: flex;
  padding: 0.875rem 1.25781rem 1.125rem 1.30469rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.875rem;
  background: var(--Pure, #2cccd3);
  transition: .2s all ease-in-out;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.downloadBtn_download_btn_container__Dl6Hz:hover {
  border-radius: 1.875rem;
  border: 1px solid var(--Pure, #2cccd3);
  background: var(--white-100, #fff);
}
.downloadBtn_download_btn_content__2EIa2 {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.downloadBtn_download_btn_text__3bP12 {
  color: #252525;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.downloadBtn_content_width__2iAa7 {
  width: 16.25rem;
  height: 3.25rem;
  display: inline-block;
}
@media screen and (max-width: 500px) {
}

.joinwaitlist_right__3Aq_z img {
	width: 450px;
	height: 450px;
}

.joinwaitlist_outer__2IFUA {
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.joinwaitlist_body__2gTyZ {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.joinwaitlist_container__asIKv {
	width: 912px;
	height: auto;
	padding: 40px;
	position: relative;
	border-radius: 24px;
	border: 1px;
	grid-gap: 24px;
	gap: 24px;
	border: 1px solid #2cccd3;
	color: #2cccd3;
	background-color: white;
}

@media screen and (max-width: 700px) {
	.joinwaitlist_container__asIKv {
		padding-bottom: 20px !important;
	}
}

.joinwaitlist_closeButton__3dRag {
	width: 48px;
	height: 48px;
	padding: 12px;
	border-radius: 50px;
	border: 1px solid #2cccd3;
	position: absolute;
	top: -10px;
	text-align: center;
	color: #2cccd3;
	right: -10px;
	background: white;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.joinwaitlist_left__1P8HZ {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	grid-gap: 24px;
	gap: 24px;
}

.joinwaitlist_left__1P8HZ .joinwaitlist_title__3IyQO {
	font-family: Merriweather Sans;
	font-size: 20px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: left;
	color: #2c3030;
}

.joinwaitlist_joinButton__1mves {
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #2c3030;
	width: 350px;
	padding: 16px;
	border-radius: 25px;
	border: 1px solid #2cccd3;
	grid-gap: 10px;
	gap: 10px;
	background: #2cccd3;
	text-align: center;
	transition: 0.2s all ease-in-out;
}

.joinwaitlist_joinButton__1mves:hover {
	background: transparent;
	border: 1px solid #2cccd3;
}

@media screen and (max-width: 700px) {
	.joinwaitlist_outer__2IFUA {
		padding-bottom: 10px;
	}
	.joinwaitlist_body__2gTyZ {
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		align-items: center;
		padding-left: 10px;
	}
	.joinwaitlist_body__2gTyZ .joinwaitlist_title__3IyQO {
		font-size: 16px;
	}
	.joinwaitlist_container__asIKv {
		padding: 30px 10px 0px 10px;
		width: 90%;
		height: 90%;
	}

	.joinwaitlist_body__2gTyZ .joinwaitlist_right__3Aq_z {
		display: none;
	}

	.joinwaitlist_body__2gTyZ input {
		width: 300px !important;
	}
	.joinwaitlist_body__2gTyZ .joinwaitlist_joinButton__1mves {
		width: 300px !important;
	}
}

.joinwaitlist_success_body__11Gkc {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 912px;
	grid-gap: 54px;
	gap: 54px;
}

.joinwaitlist_success_body__11Gkc .joinwaitlist_success_header__2q8kV {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 24px;
	gap: 24px;
}

.joinwaitlist_success_body__11Gkc .joinwaitlist_success_header__2q8kV .joinwaitlist_title__3IyQO {
	color: #2c3030;
	font-family: Merriweather Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 158.5%;
}

.joinwaitlist_success_body__11Gkc .joinwaitlist_success_header__2q8kV .joinwaitlist_des__5B5eF {
	color: #2c3030;
	width: 603px;
	text-align: center;
	font-family: Merriweather Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 158.5%; /* 25.36px */
}

.joinwaitlist_success_image__1Vtb0 img {
	width: 222.997px;
	height: 212.911px;
	flex-shrink: 0;
}

@media screen and (max-width: 700px) {
	.joinwaitlist_success_body__11Gkc {
		width: auto;
		padding-bottom: 0px;
	}
	.joinwaitlist_success_body__11Gkc .joinwaitlist_success_header__2q8kV .joinwaitlist_des__5B5eF {
		width:100%;
		font-size:14px !important;
	}
	.joinwaitlist_success_body__11Gkc .joinwaitlist_title__3IyQO {
		text-align:center;
		font-size: 16px !important;
	}
	.joinwaitlist_success_body__11Gkc .joinwaitlist_joinButton__1mves {
		width: 270px
	}
}

.LegalPolicy_container__Akmi0 {
  padding: 50px 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #2cccd3;
}

.LegalPolicy_container__Akmi0 .LegalPolicy_top__3OOJT {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.LegalPolicy_container__Akmi0 .LegalPolicy_top__3OOJT .LegalPolicy_title__11STD {
  font-family: "Natom Pro", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  color: #061237;
}

.LegalPolicy_container__Akmi0 .LegalPolicy_top__3OOJT .LegalPolicy_des__1tCaM {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #061237;
}

.LegalPolicy_container__Akmi0 .LegalPolicy_body__2m_LC {
  display: grid;
  /* width: 900px; */
  /* justify-content: space-between; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* align-items: flex-start; */
  grid-gap: 32px;
  gap: 32px;
  /* background-color: red; */
}

.LegalPolicy_body__2m_LC .LegalPolicy_general__2uRam {
  display: flex;
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
  align-items: flex-start;
}

.LegalPolicy_body__2m_LC .LegalPolicy_general__2uRam .LegalPolicy_title__11STD {
  font-family: "Natom Pro", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  color: #2c3030;
  white-space: nowrap;
}

.LegalPolicy_body__2m_LC .LegalPolicy_linkButton__3h96S {
  /* max-width: 280px; */
  width: 100%;
  padding: 10px 12px;
  border-radius: 25px;
  border: 1px solid #d2d2d2;
  grid-gap: 8px;
  gap: 8px;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #061237;
}

.LegalPolicy_linkButton__3h96S:hover {
  border: 1px solid #2cccd3;
  color: #061237;
  background-color: #b2fcff;
}

@media screen and (max-width: 700px) {
  .LegalPolicy_container__Akmi0 .LegalPolicy_top__3OOJT .LegalPolicy_title__11STD {
    font-family: "Gothic A1", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }
  .LegalPolicy_container__Akmi0 .LegalPolicy_top__3OOJT .LegalPolicy_des__1tCaM {
    font-family: "Gothic A1", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .LegalPolicy_container__Akmi0 {
    padding: 24px 18px;
    align-items: flex-start;
  }
  .LegalPolicy_container__Akmi0 .LegalPolicy_body__2m_LC {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .LegalPolicy_container__Akmi0 .LegalPolicy_body__2m_LC button {
    width: 100% !important;
    max-width: 600px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  .LegalPolicy_container__Akmi0 .LegalPolicy_general__2uRam {
    width: 100% !important;
  }
}

.footer_container__ozdOI {
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  background-color: white;
}
.footer_contentDiv__2sOIf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 76px;
  gap: 76px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.footer_leftDiv__3pSfq {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.footer_logo__1BTXo {
  width: 5.8rem;
  height: 2.2rem;
  cursor: pointer;
  transition: transform 0.3s;
}

.footer_logo__1BTXo:hover {
  transform: scale(1.1);
}

.footer_contactInfo__2evRh {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
}

.footer_contactInfo__2evRh:hover .footer_icon__2CeQN {
  transform: scale(1.25);
}
.footer_icon__2CeQN {
  /* background-color: red; */
  transition: transform 0.3s;
}
.footer_contactText__1PXph {
  text-decoration: underline;
  font-family: "Gothic A1", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #061237;
  line-height: normal;
  transition: transform 0.3s;
}

.footer_contactText__1PXph:hover {
  transform: scale(1.05);
}

.footer_centerDiv__2khPS {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footer_socialTitle__1rif6 {
  font-family: "Gothic A1", sans-serif;
  font-size: 14px;
  font-weight: 800;
  color: #061237;
  text-transform: capitalize;
  white-space: nowrap;
  line-height: normal;
}

.footer_socialIcons__3SDJX {
  background-color: white;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.footer_socialIcons__3SDJX .footer_icon__2CeQN {
  cursor: pointer;
  transition: transform 0.3s;
}

.footer_socialIcons__3SDJX .footer_icon__2CeQN:hover {
  transform: scale(1.05);
}

.footer_rightDiv__lmGtm {
  /* max-width: 382px; */
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.footer_disclaimerText__7BFoJ {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #061237;

  text-align: left;
  font-weight: 400;
}
.footer_footerText__Si-al {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
  color: #061237;
  text-transform: capitalize;
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  .footer_container__ozdOI {
    padding: 16px;
  }
  .footer_contentDiv__2sOIf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .footer_leftDiv__3pSfq {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
  }
}

