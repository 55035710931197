.gb-bg-dark-bluegreen {
	background-color: #2cccd3 !important;
}
.gb-bg-light-bluegreen {
	background-color: rgb(44 204 211 / 60%) !important;
}
.gb-textdark--bluegreen {
	color: #2cccd3 !important;
}
.my-6 {
	margin-top: 5rem !important;
	margin-bottom: 5rem !important;
}

.main-nav-bar-wrapper {
	padding: 36px 0;
	transition: 0.45s;
}
.main-nav-bar-wrapper.gb-sticky-header {
	background: rgb(0 0 0 / 80%);
	padding: 20px 0;
}
.main-nav-bar-wrapper .header-geting-start-btn:hover {
	background: #2cccd3;
	color: #000;
}
.main-nav-bar-wrapper .header-geting-start-btn {
	background: #000000;
	border: 1px solid #2cccd3;
	border-radius: 30px;
	color: #fff;
	padding: 11px 17px;
	color: #2cccd3;
	font-weight: 400;
	font-size: 15px;
	position: absolute;
}
.main-nav-bar-wrapper .cm-menu-links a {
	color: #fff;
}

@media screen and (min-width: 999px) {
	.cm-menu-links {
		display: flex;
		flex-direction: row;
		gap: 4rem;
	}
	.navbar-expand-lg .navbar-nav {
		padding-left: 10%;
	}
}
.gb-main-mobile-menu {
	font-size: 30px;
}

#main-footer .ftr-power-by-text {
	text-align: left !important;
	color: #929292;
	font-size: 14px;
}

#main-footer .ftr-copy-text {
	text-align: right !important;
	font-size: 14px;
}

/* Footer CSS Start */

@media (max-width: 767.98px) {
	/* #main-footer ul.ftr-list-links{
    margin-top: 30px;
  } */
}
/* Footer CSS End */

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
}

/* Responsive Start */
@media (max-width: 991.98px) {
	.main-nav-bar-wrapper .header-geting-start-btn {
		margin-top: 30px;
		background: #fff;
		color: #000;
		border-color: #000;
	}
	.main-nav-bar-wrapper .main-mobile-drawer {
		background: #2cccd3;
		background: radial-gradient(
			circle,
			rgba(44, 204, 211, 0.8) 0%,
			rgba(0, 0, 0, 1) 90%
		);
	}
}
@media (max-width: 768px) {
	.main-nav-bar-wrapper {
		padding: 25px 0;
		background: #000000;
	}
	.main-nav-bar-wrapper .container {
		padding-left: 20px;
		padding-right: 20px;
	}
	#main-footer {
		padding-left: 20px;
		padding-right: 20px;
		border: none;
	}
	#main-footer .cm-ftr-visa-card {
		max-width: 150px;
		margin-top: 0 !important;
	}
	#main-footer .ftr-copy-text {
		text-align: right !important;
		font-size: 10px;
	}
	#main-footer .ftr-power-by-text {
		text-align: left !important;
		color: #929292;
		font-size: 10px;
	}
	#main-footer ul.ftr-list-links,
	#main-footer .ftr-site-desc {
		margin-top: 60px !important;
	}
	#main-footer ul.ftr-list-links {
		margin-bottom: 30px;
	}
}

/* Responsive End */

.estu-font-refer {
	color: white;
}
.form-control:focus {
	color: white;
	background-color: #fff;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.estu-font-refer-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.form-check-label {
	font-size: 14px;
}

.cursor-pointer:hover {
	cursor: pointer;
}

p {
	margin-top: none;
	margin-bottom: none;
}

.App {
	overflow: hidden;
}

.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
	background-color: #2cccd3;
	color: black;
}

.sub_ctr2__box_label_popup {
	color: black;
}

.validationText {
	font-size: 11px;
	margin-top: 2px;
	margin-left: 2px;
}

.validationTexterror {
	font-size: 15px;
	margin-left: 2px;
}
