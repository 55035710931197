.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 100px;
  width: 100%;
  /* border-bottom: 1px solid #bdbdbd; */
  background: #fff;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04); */
  box-shadow: rgba(0, 0, 0, 0.18) 0px 0.602187px 0.602187px -1.25px,
    rgba(0, 0, 0, 0.16) 0px 2.28853px 2.28853px -2.5px,
    rgba(0, 0, 0, 0.063) 0px 10px 10px -3.75px;
}
.container_contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 100px;
  width: 100%;
}

.menus .mobileNav {
  display: none;
}
.menus {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px; /*TODO: may remove in future, added because tester wants estudeals and leag navbar height matching*/
}

.menus .container_contact {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.menus .container_contact .link {
  display: flex;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  margin-left: -48px;
  text-align: left;
  color: #252525;
}

.promoteButton {
  top: 0px;
  border-radius: 30px;
  background-color: #2cccd3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 18px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #252525;
}

.logo:hover {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .menus .container_contact {
    display: none;
  }
  .menus {
    justify-content: space-between;
    width: 100%;
  }
  .promoteButton {
    display: none;
  }
  .container {
    padding: 20px;
  }
  .menus .logo svg {
    width: 74px;
    height: 25px;
  }
}

@media screen and (min-width: 500px) {
  .menus .mobileNav {
    display: none;
  }
  .logo {
    padding-left: 0px;
  }
  .menus {
    padding: 0 0 0 0;
  }
}

.promoteButton {
  position: relative;
  overflow: hidden;
  top: 0px;
  border-radius: 30px;
  background-color: #2cccd3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 18px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #252525;
}

.promoteButton::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  background-color: #46dce3;
  border-radius: 50%;
  opacity: 1;
  transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.3s ease-out;
}

.promoteButton:hover::before {
  width: 500px;
  height: 600px;
  opacity: 0;
}

.mobileNavbar {
  display: none;
}

@media screen and (max-width: 500px) {
  .logo img {
    width: 139.67px;
    height: 25px;
  }
  .navContainer {
    display: none;
  }
  .button {
    display: none;
  }
  .container {
    padding: 20px 20px;
  }
  .p1 {
    width: 100%;
    gap: 0;
    justify-content: space-between;
  }
  .menuIcons {
    display: block;
  }
  .mobileNavbar .navContainer1 {
    display: flex;
    flex-direction: column;
  }
  .navContainer1 {
    gap: 50px;
    margin-top: 10px;
  }

  .mobileNavbar .close {
    width: 100%;
    display: flex;
    align-items: flex-end;
    text-align: right;
    flex-direction: column;
  }

  .navContainer1 .links {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #252525;
  }
  .mobileNavbar {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    background-color: #ffffff;
    z-index: 1000000;
    padding: 50px 40px;
    justify-content: space-between;
    width: 90vw;
    box-shadow: -3px -9px 20px #80808045;
  }
  .button1 {
    border-radius: 100px;
    padding: 16px 44px;
    background: #665ee0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    transition: 0.3s all ease-in-out;
    border: 1px solid #665ee0;
  }

  .button1:hover {
    cursor: pointer;
    color: #665ee0;
    background: transparent;
  }
  .show_btn {
    display: none;
  }
}
.promoteButton1 {
  position: relative;
  overflow: hidden;
  top: 0px;
  border-radius: 30px;
  background-color: #2cccd3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 10px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  color: #252525;
}

.promoteButton1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  background-color: #46dce3;
  border-radius: 50%;
  opacity: 1;
  transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.3s ease-out;
}

.promoteButton1:hover::before {
  width: 500px;
  height: 600px;
  opacity: 0;
}
